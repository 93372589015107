import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router/index'
import { createStore } from 'vuex'
import stores from './store/index'
import { initializeApp } from "firebase/app";

const router = createRouter({
    base: process.env.VUE_APP_BASE_APP_URL,
    history: createWebHistory(process.env.VUE_APP_BASE_APP_URL),
    routes,
    mode:'history'
})

const store = createStore(stores)
const app = createApp(App);

const firebaseConfig = {
    apiKey: "AIzaSyCRfGnfcDs7tBvesdDvsRJ4L8SXyBFq5z8",
    authDomain: "dcp-test-186fb.firebaseapp.com",
    projectId: "dcp-test-186fb",
    storageBucket: "dcp-test-186fb.appspot.com",
    messagingSenderId: "158257781334",
    appId: "1:158257781334:web:5c8499ba30688ee17e39f7",
    measurementId: "G-2KVHDBJJMT"
};
initializeApp(firebaseConfig);
// const firbaseObj = initializeApp(firebaseConfig);

app.use(router);
app.use(store);
app.mount('#app');
  
